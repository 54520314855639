// getLeaderboardIcons.js
'use strict';

import firstPlaceIcon from '../../img/ic_crown_first.svg';
import secondPlaceIcon from '../../img/ic_crown_second.svg';
import thirdPlaceIcon from '../../img/ic_crown_third.svg';
import rankNumberOneIcon from '../../img/ic-leaderboard-badge-no-1.svg';
import rankNumberTwoIcon from '../../img/ic-leaderboard-badge-no-2.svg';
import rankNumberThreeIcon from '../../img/ic-leaderboard-badge-no-3.svg';

export default {
  place: {
    1: firstPlaceIcon,
    2: secondPlaceIcon,
    3: thirdPlaceIcon,
  },
  rank: {
    1: rankNumberOneIcon,
    2: rankNumberTwoIcon,
    3: rankNumberThreeIcon,
  },
};
